import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
const useUser = () => {
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/users/${id}`);
  const { postData } = usePostData();
  const [userData, setUserData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setUserData(data);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleDelete = async () => {
    try {
      await postData(`/users/delete`, { id: id });
    } catch (error) {
      handleError(error, error.message);
      return;
    }
  };
  const handleUpdate = async (user) => {
    try {
      setSubmitLoading(true);
      await postData(`/user/update`, { id: id, ...user });
      setSubmitLoading(false);
      window.location.reload();
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);

      return;
    }
  };
  const handleDefaulter = async (remarks) => {
    setPopupLoading(true);
    try {
      await postData(`/defaulters`, {
        userId: userData?.user?.id,
        reason: remarks,
      });
      setPopupLoading(false);
      window.location.reload();
    } catch (error) {
      handleError(error, error.message);
      return;
    }
  };
  const handleOTP = async (otp) => {
    setPopupLoading(true);
    try {
      await postData(`/user/approve`, {
        id: userData?.user?.id,
        otp: otp,
      });
      setPopupLoading(false);
      window.location.reload();
    } catch (error) {
      setPopupLoading(false);
      handleError(error, error.message);
      return;
    }
  };
  const generateOTP = async () => {
    setPopupLoading(true);
    try {
      await postData(`/user/otp`, {
        id: userData?.user?.id,
      });
      setPopupLoading(false);
      window.location.reload();
    } catch (error) {
      setPopupLoading(false);
      handleError(error, error.message);
      return;
    }
  };
  return {
    userData,
    loading,
    error,
    handleDelete,
    handleUpdate,
    submitLoading,
    handleDefaulter,
    popupLoading,
    handleOTP,
    generateOTP,
  };
};

export default useUser;
