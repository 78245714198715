import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { styled, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "var(--button-primary)",
  "white-space": "nowrap",
  color: "#fff",
  padding: "0.5rem 2rem",
  "&:hover": {
    backgroundColor: "var(--button-hover)",
  },
}));

const EnhancedTableToolbar = ({
  numSelected,
  name,
  showButton,
  handleButton,
}) => (
  <>
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
        color="var(--text-heading)"
      >
        {name}
      </Typography>
      {showButton && (
        <StyledButton onClick={handleButton}>New {name}</StyledButton>
      )}
    </Toolbar>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
  </>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

const CustomDataGrid = ({
  rows,
  headCells,
  type,
  selectableRows,
  clickableRows,
  showButton,
  handleButton,
}) => {
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          "& .MuiButton-root": {
            color: "var(--text-primary)", // Color of toolbar buttons
            borderColor: "var(--button-hover)",
          },
          "& .MuiSvgIcon-root": {
            color: "var(--button-hover)", // Color of toolbar icons
          },
          "& .MuiSvgIcon-root:hover": {
            color: "var(--button-hover)", // Color of toolbar icons
          },
          "& .MuiButton-root:hover": {
            color: "var(--button-hover)", // Color of toolbar icons
            borderColor: "var(--button-hover)",
          },
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport slotProps={{ button: { variant: "outlined" } }} />
      </GridToolbarContainer>
    );
  }
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Paper sx={{ width: "100%", mb: 3 }}>
        <EnhancedTableToolbar
          numSelected={selectionModel.length}
          name={type}
          showButton={showButton}
          handleButton={handleButton}
        />
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={headCells}
            pageSize={12}
            getRowId={(row) => row.id}
            autoPageSize
            checkboxSelection={selectableRows}
            disableSelectionOnClick={!clickableRows}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            onRowClick={(params) => {
              if (clickableRows) {
                navigate(`/${type.toLowerCase()}/${params.id}`);
              }
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            selectionModel={selectionModel}
          />
        </div>
      </Paper>
    </Box>
  );
};

CustomDataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      numeric: PropTypes.bool.isRequired,
      disablePadding: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  selectableRows: PropTypes.bool,
  clickableRows: PropTypes.bool,
};

CustomDataGrid.defaultProps = {
  selectableRows: false,
  clickableRows: true,
};

export default CustomDataGrid;
