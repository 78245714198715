import React from "react";
import Modal from "@mui/material/Modal";
import { useTheme } from "../../../contexts/themeContext";
import styles from "./index.module.css";
import Button from "../../common/button";
import CustomSelect from "../../common/select";
import CustomInput from "../../common/customInput";
const FundModal = ({
  onClick,
  open,
  setOpen,
  loading,
  error,
  disabled,
  fundType,
  setFundType,
  phoneNumber,
  setPhoneNumber,
  serviceCode,
  setServiceCode,
}) => {
  const { darkMode } = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  const service_codes = [
    { label: "Airtel", value: "ZM-AIRTEL-DISBURSEMENT" },
    { label: "MTN", value: "ZM-MTN-DISBURSEMENT" },
    { label: "Zamtel", value: "ZM-ZAMTEL-DISBURSEMENT" },
  ];
  return (
    <Modal
      open={open}
      data-theme={darkMode ? "dark" : "light"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal}>
        <div className={styles.container}>
          <h3>Select the Fund Method</h3>
          <div className={styles.container__inner}>
            <Button
              children={"Fund Manually"}
              onClick={() => {
                setFundType("Manual");
                onClick();
              }}
              loading={loading}
            />
            <Button
              children={"Fund Via Tingg"}
              onClick={() => {
                setFundType("Tingg");
              }}
            />
          </div>
          {fundType === "Tingg" && (
            <div className={styles.specify}>
              <CustomInput
                value={phoneNumber}
                label={"Phone Number"}
                className={styles.modal__input}
                onChange={(e) => {
                  let value = e.target.value;

                  if (!value.startsWith("26")) {
                    value = "26" + value;
                  }

                  setPhoneNumber(value);
                }}
                error={error}
              />
              <CustomSelect
                label="Service Provider"
                options={service_codes}
                value={serviceCode}
                className={styles.input}
                onChange={(e) => {
                  setServiceCode(e[0]);
                  console.log(e[0]);
                }}
              />
              <Button
                children={"Disburse"}
                onClick={onClick}
                loading={loading}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default FundModal;
