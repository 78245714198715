import React, { useEffect, useState, useContext } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./index.module.css";
import CustomRadio from "./../../common/customRadio";
import CustomSelect from "../../common/select";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import CountrySelect from "../../common/countrySelect";
import ResponsiveDatePicker from "../../common/datePicker";
import CustomFileInput from "../../common/customFIleInput";
import { Skeleton } from "@mui/material";
import moment from "moment";
import useApply from "./useApply";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import {
  validateTitle,
  validateFirstName,
  validateSurName,
  validateGender,
  validatePhoneNumber,
  validateNRCNumber,
  validateDateOfBirth,
  validateEmail,
  validateResidentialAddressNumber,
  validateStreetName,
  validateResidentialArea,
  validateNationality,
  validateBankName,
  validateAccountNumber,
  validateLoanType,
  validateAddressDuration,
  validateNetMonthlyIncome,
  validateEmploymentStatus,
  validatePublicService,
  validateEmployer,
  validateEmployeeNumber,
  validateLoanAmount,
  validateDocument,
  validateUser,
  validateLoanInformation,
  validateLoanFinances,
  validateLoanDocuments,
} from "./validationApply";
const steps = [
  "Select User or Create One",
  "Loan Information",
  "Loan Finances",
  "Loan Documents",
];
const titleType = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Miss.", value: "Miss." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
];
const genderType = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female." },
  { label: "Other", value: "Other" },
];
const employmentStatus = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No." },
];
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  backgroundColor:
    ownerState.active || ownerState.completed
      ? "var(--text-heading)"
      : "var(--text-primary)",
  borderRadius: "50%",
  color: "black",
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {completed ? <CheckIcon /> : props.icon}
    </CustomStepIconRoot>
  );
}

export default function ApplyForLoan() {
  const navigate = useNavigate();
  const { user: AuthenticatedUser } = useContext(AuthContext);
  useEffect(() => {
    if (AuthenticatedUser) {
      if (!AuthenticatedUser?.admin?.permissions?.permissions?.applyForLoan) {
        navigate("/dashboard");
      }
    }
  }, [AuthenticatedUser, navigate]);
  const [userType, setUserType] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const {
    users,
    loading,
    user,
    setUser,
    userData,
    handleUserDataChange,
    loanData,
    handleLoanDataChange,
    applyLoading,
    handleApplyLoan,
    interestRate,
    loadingInterest,
    productData,
  } = useApply();
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({
    title: "",
    firstName: "",
    surName: "",
    gender: "",
    phoneNumber: "",
    NRCNumber: "",
    dateOfBirth: "",
    email: "",
    residentialAddressNumber: "",
    streetName: "",
    residentialArea: "",
    nationality: "",
    bankName: "",
    accountNumber: "",
    loanType: "",
    addressDuration: "",
    netMonthlyIncome: "",
    employmentStatus: "",
    publicService: "",
    employer: "",
    employeeNumber: "",
  });
  const [productType, setProductType] = useState(null);
  useEffect(() => {
    const usersData = users?.users?.map((user) => {
      return {
        label: `${user.firstName} ${user.surName}`,
        value: user.id,
      };
    });
    setUsersList(usersData);
  }, [users]);
  const handleNext = () => {
    if (activeStep === 0) {
      if (userType === 0) {
        if (!user) {
          setDisabled(true);
        } else {
          setDisabled(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        const validationErrors = validateUser(userData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
          setDisabled(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setDisabled(true);
        }
      }
    } else if (activeStep === 1) {
      const validationErrors = validateLoanInformation(loanData);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setDisabled(true);
      }
    } else if (activeStep === 2) {
      const validationErrors = validateLoanFinances(loanData);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setDisabled(true);
      }
    }
  };
  const loanTypes = productData
    ? productData?.products?.map((product) => {
        return { value: product.name, label: product.name };
      })
    : [];
  useEffect(() => {
    if (activeStep === 0) {
      if (userType === 0) {
        if (!user) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } else {
        const validationErrors = validateUser(userData);
        // setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } else if (activeStep === 1) {
      const validationErrors = validateLoanInformation(loanData);
      // setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (activeStep === 2) {
      const validationErrors = validateLoanFinances(loanData);
      // setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (activeStep === 3) {
      const validationErrors = validateLoanDocuments(loanData);
      // setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [activeStep, userData, userType, user, loanData]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleApply = async () => {
    if (activeStep === 3) {
      const validationErrors = validateLoanDocuments(loanData);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        setDisabled(false);
        const identity = document.querySelector(`#identityDocument`);
        const pay = document.querySelector(`#paySlip1`);
        const pay2 = document.querySelector(`#paySlip2`);
        const proof = document.querySelector(`#proofOfAddress`);
        const pre = document.querySelector(`#preApprovedLetter`);
        const bank = document.querySelector(`#bankStatement`);
        const applicationForm = document.querySelector(`#applicationForm`);

        await handleApplyLoan(
          identity,
          pay,
          pay2,
          pre,
          proof,
          bank,
          applicationForm,
          userType
        );
      } else {
        setDisabled(true);
      }
    } else {
      return;
    }
  };
  const [interestRates, setInterestRates] = useState(null);
  const [productComplete, setProductComplete] = useState(null);
  const handleProductChange = (value) => {
    const productNew = productData?.products.find(
      (product) => product.name === value
    );
    setProductType(value);
    setProductComplete(productNew);
    handleLoanDataChange("loanType")(productNew.id);
    setInterestRates(productNew?.Interests[0]);
  };
  function generateMonthsArray(minMonths, maxMonths) {
    const monthsArray = [];

    for (let month = minMonths; month <= maxMonths; month++) {
      monthsArray.push({
        value: `${month}`,
        label: `${month} ${month === 1 ? "Month" : "Months"}`,
      });
    }

    return monthsArray;
  }
  const loanPeriodArray = generateMonthsArray(
    productComplete?.min_months || 0,
    productComplete?.max_months || 0
  );
  useEffect(() => {
    if (loanData.loanAmount && loanData?.loanPeriod) {
      const interestTemp = parseFloat(
        parseInt(loanData?.loanAmount) *
          ((interestRates.monthly_interest * parseInt(loanData?.loanPeriod)) /
            100)
      );
      const processingFeeTemp = parseFloat(
        parseInt(loanData?.loanAmount) * (interestRates.processing_fee / 100)
      );
      const insuranceFeeTemp = parseFloat(
        parseInt(loanData?.loanAmount) * (interestRates.insurance_fee / 100)
      );

      handleLoanDataChange("interestFee")(interestTemp.toFixed(2));
      handleLoanDataChange("processingFee")(processingFeeTemp.toFixed(2));
      handleLoanDataChange("insuranceFee")(insuranceFeeTemp.toFixed(2));
      const totalTemp =
        parseInt(loanData?.loanAmount) +
        parseFloat(interestTemp) +
        parseFloat(processingFeeTemp) +
        parseFloat(insuranceFeeTemp);
      handleLoanDataChange("total")(totalTemp.toFixed(2));
      handleLoanDataChange("monthlyInstallments")(
        (totalTemp / parseInt(loanData?.loanPeriod)).toFixed(2)
      );
    }
  }, [loanData, interestRate, handleLoanDataChange, interestRates]);
  const handleUploadFileChange = (event, type) => {
    handleError(type)(
      validateDocument(
        {
          extension: event.target.files[0]?.type.split("/")[1],
          name: type,
        },
        type
      )
    );
    handleLoanDataChange(type)({
      extension: event.target.files[0]?.type.split("/")[1],
      name: type,
    });
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(`#${type}`);
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleError = (field) => (event) => {
    setErrors((prevState) => ({
      ...prevState,
      [field]: event,
    }));
  };

  const publicService = [
    { value: "Not A Public Servant", label: "Not A Public Servant" },
    { value: "Government Departments", label: "Government Departments" },
    { value: "Air Force", label: "Air Force" },
    { value: "Army", label: "Army" },
    { value: "National Services", label: "National Services" },
  ];
  return (
    <section className={styles.apply}>
      {loading || loadingInterest ? (
        <>
          <Skeleton width={"90%"} height={"90%"} variant="rounded" />
        </>
      ) : (
        <>
          <div className={styles.stepper}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {
                  StepIconComponent: CustomStepIcon,
                };

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      {...labelProps}
                      sx={{
                        "& .MuiStepLabel-label": {
                          color:
                            activeStep === index
                              ? "var(--text-heading)"
                              : "var(--text-primary)",
                        },
                        "& .MuiStepIcon-root": {
                          color:
                            activeStep === index
                              ? "var(--text-heading)"
                              : "var(--text-primary)",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div className={styles.form}>
            {activeStep === 0 && (
              <div className={styles.form__inner}>
                <div className={styles.component}>
                  <CustomRadio
                    label={"Existing User"}
                    onChange={() => {
                      setUserType(0);
                    }}
                    value={0}
                    selectedValue={userType}
                  />
                  <CustomRadio
                    label={"Create a new User"}
                    onChange={() => {
                      setUserType(1);
                    }}
                    value={1}
                    selectedValue={userType}
                  />
                </div>
                {userType === 0 ? (
                  <div className={styles.component}>
                    <CustomSelect
                      label={"Select User"}
                      options={usersList}
                      className={styles.input}
                      value={user}
                      onChange={(e) => setUser(e[0])}
                    />
                  </div>
                ) : (
                  <div className={styles.component}>
                    <CustomSelect
                      label={"Title"}
                      options={titleType}
                      className={styles.input}
                      value={userData.title}
                      onChange={(e) => {
                        handleError("title")(validateTitle(e[0]));
                        handleUserDataChange("title")(e[0]);
                      }}
                      error={errors.title}
                    />
                    <CustomInput
                      label={"First Name"}
                      type="text"
                      className={styles.input}
                      value={userData.firstName}
                      onChange={(e) => {
                        handleError("firstName")(
                          validateFirstName(e.target.value)
                        );
                        handleUserDataChange("firstName")(e.target.value);
                      }}
                      error={errors.firstName}
                    />
                    <CustomInput
                      label={"Last Name"}
                      type="text"
                      className={styles.input}
                      value={userData.surName}
                      onChange={(e) => {
                        handleError("surName")(validateSurName(e.target.value));
                        handleUserDataChange("surName")(e.target.value);
                      }}
                      error={errors.surName}
                    />
                    <CustomSelect
                      label={"Gender"}
                      options={genderType}
                      className={styles.input}
                      value={userData.gender}
                      onChange={(e) => {
                        handleError("gender")(validateGender(e[0]));
                        handleUserDataChange("gender")(e[0]);
                      }}
                      error={errors.gender}
                    />
                    <CustomInput
                      label={"Phone Number"}
                      className={styles.input}
                      value={userData.phoneNumber}
                      onChange={(e) => {
                        handleError("phoneNumber")(
                          validatePhoneNumber(e.target.value)
                        );
                        handleUserDataChange("phoneNumber")(e.target.value);
                      }}
                      error={errors.phoneNumber}
                    />
                    <CustomInput
                      label={"NRC Number"}
                      className={styles.input}
                      value={userData.NRCNumber}
                      onChange={(e) => {
                        handleError("NRCNumber")(
                          validateNRCNumber(e.target.value)
                        );
                        handleUserDataChange("NRCNumber")(e.target.value);
                      }}
                      error={errors.NRCNumber}
                    />
                    <ResponsiveDatePicker
                      width={"30%"}
                      label={"Date Of Birth"}
                      className={styles.input}
                      value={userData.dateOfBirth}
                      onChange={(value) => {
                        const date = new Date(value);
                        const formattedDate =
                          moment(date)?.format("YYYY-MM-DD");
                        handleError("dateOfBirth")(
                          validateDateOfBirth(formattedDate)
                        );
                        handleUserDataChange("dateOfBirth")(formattedDate);
                      }}
                      disableFuture
                      error={errors.dateOfBirth}
                    />
                    <CustomInput
                      label={"Email"}
                      type="email"
                      className={styles.input}
                      value={userData.email}
                      onChange={(e) => {
                        handleError("email")(validateEmail(e.target.value));
                        handleUserDataChange("email")(e.target.value);
                      }}
                      error={errors.email}
                    />
                    <CustomInput
                      label={"Address Number"}
                      type="text"
                      className={styles.input}
                      value={userData.residentialAddressNumber}
                      onChange={(e) => {
                        handleError("residentialAddressNumber")(
                          validateResidentialAddressNumber(e.target.value)
                        );
                        handleUserDataChange("residentialAddressNumber")(
                          e.target.value
                        );
                      }}
                      error={errors.residentialAddressNumber}
                    />
                    <CustomInput
                      label={"Street Name"}
                      type="text"
                      className={styles.input}
                      value={userData.streetName}
                      onChange={(e) => {
                        handleError("streetName")(
                          validateStreetName(e.target.value)
                        );
                        handleUserDataChange("streetName")(e.target.value);
                      }}
                      error={errors.streetName}
                    />
                    <CustomInput
                      label={"Residential Area"}
                      type="text"
                      className={styles.input}
                      value={userData.residentialArea}
                      onChange={(e) => {
                        handleError("residentialArea")(
                          validateResidentialArea(e.target.value)
                        );
                        handleUserDataChange("residentialArea")(e.target.value);
                      }}
                      error={errors.residentialArea}
                    />
                    <CountrySelect
                      className={styles.input}
                      value={userData.nationality}
                      onChange={(e) => {
                        handleError("nationality")(validateNationality(e));
                        handleUserDataChange("nationality")(e);
                      }}
                      error={errors.nationality}
                    />
                    <CustomInput
                      label={"Bank Name"}
                      type="text"
                      className={styles.input}
                      value={userData.bankName}
                      onChange={(e) => {
                        handleError("bankName")(
                          validateBankName(e.target.value)
                        );
                        handleUserDataChange("bankName")(e.target.value);
                      }}
                      error={errors.bankName}
                    />
                    <CustomInput
                      label={"Account Number"}
                      type="text"
                      className={styles.input}
                      value={userData.accountNumber}
                      onChange={(e) => {
                        handleError("accountNumber")(
                          validateAccountNumber(e.target.value)
                        );
                        handleUserDataChange("accountNumber")(e.target.value);
                      }}
                      error={errors.accountNumber}
                    />
                  </div>
                )}
              </div>
            )}
            {activeStep === 1 && (
              <div className={styles.form__inner}>
                <div className={styles.component}>
                  <CustomSelect
                    label={"Loan Type"}
                    options={loanTypes}
                    className={styles.input}
                    value={productType}
                    onChange={(e) => {
                      handleError("loanType")(validateLoanType(e[0]));
                      handleProductChange(e[0]);
                    }}
                    error={errors.loanType}
                  />
                  <CustomInput
                    label={"Address Duration"}
                    type="text"
                    className={styles.input}
                    value={loanData.addressDuration}
                    onChange={(e) => {
                      handleError("addressDuration")(
                        validateAddressDuration(e.target.value)
                      );
                      handleLoanDataChange("addressDuration")(e.target.value);
                    }}
                    error={errors.addressDuration}
                  />
                  <CustomInput
                    label={"Income"}
                    type="text"
                    className={styles.input}
                    value={loanData.netMonthlyIncome}
                    onChange={(e) => {
                      handleError("netMonthlyIncome")(
                        validateNetMonthlyIncome(e.target.value)
                      );
                      handleLoanDataChange("netMonthlyIncome")(e.target.value);
                    }}
                    error={errors.netMonthlyIncome}
                  />
                  <CustomSelect
                    label={"Employment Status"}
                    options={employmentStatus}
                    className={styles.input}
                    value={loanData.employmentStatus}
                    onChange={(e) => {
                      handleError("employmentStatus")(
                        validateEmploymentStatus(e[0])
                      );
                      handleLoanDataChange("employmentStatus")(e[0]);
                    }}
                    error={errors.employmentStatus}
                  />
                  <CustomSelect
                    label={"Public Service"}
                    options={publicService}
                    className={styles.input}
                    value={loanData.publicService}
                    onChange={(e) => {
                      handleError("publicService")(validatePublicService(e[0]));
                      handleLoanDataChange("publicService")(e[0]);
                    }}
                    error={errors.publicService}
                  />
                  <CustomInput
                    label={"Employer"}
                    className={styles.input}
                    value={loanData.employer}
                    onChange={(e) => {
                      handleError("employer")(validateEmployer(e.target.value));
                      handleLoanDataChange("employer")(e.target.value);
                    }}
                    error={errors.employer}
                  />

                  <CustomInput
                    label={"Employee Number"}
                    className={styles.input}
                    value={loanData.employeeNumber}
                    onChange={(e) => {
                      handleError("employeeNumber")(
                        validateEmployeeNumber(e.target.value)
                      );
                      handleLoanDataChange("employeeNumber")(e.target.value);
                    }}
                    error={errors.employeeNumber}
                  />
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div className={styles.form__inner}>
                <div className={styles.component}>
                  <CustomInput
                    label={"Loan Amount"}
                    className={styles.input}
                    value={loanData.loanAmount}
                    onChange={(e) => {
                      handleError("loanAmount")(
                        validateLoanAmount(e.target.value)
                      );
                      handleLoanDataChange("loanAmount")(e.target.value);
                    }}
                    error={errors.loanAmount}
                  />
                  <CustomSelect
                    label={"Loan Duration"}
                    options={loanPeriodArray}
                    className={styles.input}
                    value={loanData.loanPeriod}
                    onChange={(e) => {
                      // handleError("publicService")(validatePublicService(e[0]));
                      // handleLoanDataChange("publicService")(e[0]);
                      handleLoanDataChange("loanPeriod")(e[0]);
                    }}
                    error={errors.publicService}
                  />
                  {/* <CustomInput
                    label={"Loan Duration"}
                    type="text"
                    className={styles.input}
                    value={loanData.loanPeriod}
                    onChange={(e) => {
                      handleLoanDataChange("loanPeriod")(e.target.value);
                    }}
                    error={errors.loanPeriod}
                  /> */}
                  <CustomInput
                    label={"Interest Fee"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.interestFee}
                  />
                  <CustomInput
                    label={"Insurance Fee"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.insuranceFee}
                  />
                  <CustomInput
                    label={"Processing Fee"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.processingFee}
                  />
                  <CustomInput
                    label={"Penalty Fee"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.penaltyFee}
                  />
                  <CustomInput
                    label={"Monthly Installments (Approximate)"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.monthlyInstallments}
                  />
                  <CustomInput
                    label={"Total"}
                    type="text"
                    disabled
                    className={styles.input}
                    value={loanData.total}
                  />
                </div>
              </div>
            )}
            {activeStep === 3 && (
              <div className={styles.form__inner}>
                <div className={styles.component}>
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="identityDocument"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="bankStatement"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="paySlip1"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="paySlip2"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="proofOfAddress"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="preApprovedLetter"
                  />
                  <input
                    className={styles.hidden__input}
                    type="file"
                    id="applicationForm"
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Identity Document"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "identityDocument");
                    }}
                    error={errors.identityDocument}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Pay Slip #1"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "paySlip1");
                    }}
                    error={errors.paySlip1}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Pay Slip #2"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "paySlip2");
                    }}
                    error={errors.paySlip2}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Bank Statement"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "bankStatement");
                    }}
                    error={errors.bankStatement}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Proof of Address"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "proofOfAddress");
                    }}
                    error={errors.proofOfAddress}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Pre Approved Letter (Optional)"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "preApprovedLetter");
                    }}
                    error={errors.preApprovedLetter}
                  />
                  <CustomFileInput
                    type="file"
                    className={styles.input}
                    label={"Application Form (Optional)"}
                    disabled={false}
                    accept=".jpg,.png,.pdf"
                    onChange={(e) => {
                      handleUploadFileChange(e, "applicationForm");
                    }}
                    error={errors.applicationForm}
                  />
                </div>
              </div>
            )}
            <div className={styles.buttons}>
              {activeStep > 0 && (
                <Button onClick={handleBack} className={styles.prev}>
                  Previous
                </Button>
              )}
              {activeStep >= 0 && activeStep !== steps.length - 1 && (
                <Button
                  onClick={handleNext}
                  className={styles.next}
                  disabled={disabled}
                >
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  onClick={handleApply}
                  className={styles.submit}
                  loading={applyLoading}
                  disabled={disabled}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
}
