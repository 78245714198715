import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import CustomSelect from "../../common/select";
import CountrySelect from "../../common/countrySelect";
import Chip from "@mui/material/Chip";
import useLoan from "./useLoan";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "../../common/customTable";
import { toTitleCase } from "../../../utils/titleCase";
import ResponsiveDatePicker from "../../common/dateTimePicker";
import DeleteModal from "../../common/deleteModal";
import ActionModal from "./actionModal";
import PayModal from "./payModal";
import RequestModal from "./requestModal";
import ExtendModal from "./extendModal";
import FundModal from "./fundModal";
import UploadModal from "./uploadModal";

import CRB from "./CRB";
import LoanPDFDownload from "./LoanStatement";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import {
  validateLoanType,
  validateAddressDuration,
  validateNetMonthlyIncome,
  validateEmploymentStatus,
  validatePublicService,
  validateEmployer,
  validateEmployeeNumber,
  validateLoanInformation,
  validateDocument,
  validateRemarks,
  validateAmount,
} from "./validationLoan";
const Loan = () => {
  const {
    loanData,
    loading,
    deleteLoading,
    handleDelete,
    handleUpdate,
    saveChanges,
    handleUpdateStatus,
    popupLoading,
    handlePayInstallment,
    handleRequest,
    handleExtend,
    productData,
    checkCRB,
    reportData,
    handleFund,
    handleUploadAttachment,
  } = useLoan();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [payModal, setPayModal] = useState(false);
  const [fundModal, setFundModal] = useState(false);
  const [fundType, setFundType] = useState("Manual");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceCode, setServiceCode] = useState(false);
  const [requestModal, setRequestModal] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [extendModal, setExtendModal] = useState(null);
  const [month, setMonth] = useState(1);
  const [requestType, setRequestType] = useState("File");
  const [amount, setAmount] = useState(0);
  const [remark, setRemark] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [modalDisable, setModalDisable] = useState(false);
  const [requestDisable, setRequestDisable] = useState(false);
  const [amountDisable, setAmountDisable] = useState(false);
  const [CRBCheck, setCRBCheck] = useState(false);
  const navigate = useNavigate();
  const { user: AuthenticatedUser } = useContext(AuthContext);
  useEffect(() => {
    if (AuthenticatedUser) {
      if (!AuthenticatedUser?.admin?.permissions?.permissions?.viewLoan) {
        navigate("/dashboard");
      }
    }
  }, [AuthenticatedUser, navigate, productData]);
  const handleCRB = () => {
    setActionModal(true);
  };
  const handleAction = async () => {
    const file = document.querySelector(`#attachment`);
    await handleUpdateStatus(modalType, remark, attachment, file);
  };
  const handleAttachment = (event) => {
    handleError("attachment")(
      validateDocument({
        extension: event.target.files[0]?.type.split("/")[1],
        name: "Attachment",
      })
    );
    setAttachment({
      extension: event.target.files[0]?.type.split("/")[1],
      name: "Attachment",
    });
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(`#attachment`);
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  function classifyPhoneNumber(phoneNumber) {
    // Remove the country code if present (260 for Zambia)
    const nsn = phoneNumber?.startsWith("260")
      ? phoneNumber?.slice(3)
      : phoneNumber;

    // Extract the first two significant digits
    const leadingDigits = nsn?.slice(0, 2);
    // Classification logic
    if (leadingDigits === "95") {
      return "ZAMTEL";
    } else if (leadingDigits === "96" || leadingDigits === "76") {
      return "MTN";
    } else if (leadingDigits === "97" || leadingDigits === "77") {
      return "AIRTEL";
    } else {
      return "Unknown";
    }
  }
  const [user, setUser] = useState({
    firstName: "",
    surName: "",
    phoneNumber: "",
    accountNumber: "",
    bankName: "",
    dateOfBirth: "",
    email: "",
    gender: "",
    password: "",
    status: "",
    country: "",
    NRCNumber: "",
    verified: "",
    loginAttempt: "",
    residentialAddressNumber: "",
    residentialArea: "",
    streetName: "",
    title: "",
    otp: "",
    otpExpiresAtsAt: "",
    createdAtdAt: "",
    loans: "",
    profilePhoto: "",
  });
  const [formData, setFormData] = useState({
    loanType: "",
    addressDuration: "",
    netMonthlyIncome: "",
    employmentStatus: "",
    publicService: "",
    employer: "",
    employeeNumber: "",
    loanAmount: "",
    loanDuration: "",
    interestFee: "",
    processingFee: "",
    insuranceFee: "",
    penaltyFee: "",
    monthlyInstallments: "",
    total: "",
    identityDocument: null,
    paySlip1: null,
    paySlip2: null,
    proofOfAddress: null,
    preApprovedLetter: null,
    applicationForm: null,
  });
  useEffect(() => {
    setFormData({
      loanType: loanData?.loan?.loanType || "",
      loanId: loanData?.loan?.loanId || "",
      status: loanData?.loan?.status || "",
      addressDuration: loanData?.loan?.addressDuration || "",
      netMonthlyIncome: loanData?.loan?.netMonthlyIncome || "",
      employmentStatus: loanData?.loan?.employmentStatus || "",
      createdAt: loanData?.loan?.createdAt || "",
      publicService: loanData?.loan?.publicService || "",
      employer: loanData?.loan?.employer || "",
      employeeNumber: loanData?.loan?.employeeNumber || "",
      loanAmount: loanData?.loan?.loanAmount || "",
      netLoanAmount: loanData?.loan?.netLoanAmount || "",
      loanDuration: loanData?.loan?.loanPeriod || "",
      interestFee: loanData?.loan?.interest || "",
      processingFee: loanData?.loan?.processingFee || "",
      insuranceFee: loanData?.loan?.insuranceFee || "",
      penaltyFee: loanData?.loan?.overdueLoan || "",
      monthlyInstallments: loanData?.loan?.loanType || "",
      total: loanData?.loan?.netLoanAmount || "",
      attachments: [
        {
          id: 1,
          name: "Identity Document",
          url: loanData?.loan?.attachments?.identityDocument || "",
          type: "identityDocument",
        },

        {
          id: 2,
          name: "Pay Slip # 1",
          url: loanData?.loan?.attachments?.payslip1 || "",
          type: "payslip1",
        },
        {
          id: 3,
          name: "Pay Slip 2",
          url: loanData?.loan?.attachments?.payslip2 || "",
          type: "payslip2",
        },
        {
          id: 4,
          name: "Proof of Address",
          url: loanData?.loan?.attachments?.addressProof || "",
          type: "addressProof",
        },
        {
          id: 5,
          name: "Pre Approved Letter",
          url: loanData?.loan?.attachments?.preApprovedLetter || "",
          type: "preApprovedLetter",
        },
        {
          id: 6,
          name: "Bank Statement",
          url: loanData?.loan?.attachments?.bankStatement || "",
          type: "bankStatement",
        },
        {
          id: 7,
          name: "Application Form",
          url: loanData?.loan?.attachments?.applicationForm || "",
          type: "applicationForm",
        },
      ],
      insuranceRate: loanData?.loan?.insuranceRate || "",
      interestRate: loanData?.loan?.interestRate || "",
      processingRate: loanData?.loan?.processingRate || "",
      penaltyRate: loanData?.loan?.penaltyRate || "",
      paidInsuranceFee: loanData?.loan?.paidInsuranceFee || "",
      paidInterest: loanData?.loan?.paidInterest || "",
      paidProcessingFee: loanData?.loan?.paidProcessingFee || "",
      paidPenalty: loanData?.loan?.penaltyAmount || "",
      paidLoan: loanData?.loan?.paidLoan || "",
      installmentDate: loanData?.loan?.installmentDate || [],
      timeline: loanData?.loan?.timeline || [],
      requests: loanData?.loan?.requests || [],
      payments: loanData?.loan?.payments || [],
    });
    setUser({
      id: loanData?.loan?.user?.id || "",
      firstName: loanData?.loan?.user?.firstName || "",
      surName: loanData?.loan?.user.surName || "",
      phoneNumber: loanData?.loan?.user.phoneNumber || "",
      accountNumber: loanData?.loan?.user.accountNumber || "",
      bankName: loanData?.loan?.user.bankName || "",
      dateOfBirth: loanData?.loan?.user.dateOfBirth || "",
      email: loanData?.loan?.user.email || "",
      gender: loanData?.loan?.user.gender || "",
      userId: loanData?.loan?.user?.userId || "",
      password: "",
      status: loanData?.loan?.user.status || "",
      country: loanData?.loan?.user?.nationality || "Zambia",
      NRCNumber: loanData?.loan?.user.NRCNumber || "",
      verified: loanData?.loan?.user.isVerified || "",
      loginAttempt: loanData?.loan?.user.loginAttempt || "",
      residentialAddressNumber:
        loanData?.loan?.user.residentialAddressNumber || "",
      residentialArea: loanData?.loan?.user.residentialArea || "",
      streetName: loanData?.loan?.user.streetName || "",
      title: loanData?.loan?.user.title || "",
      otp: "",
      otpExpiresAt: loanData?.loan?.user.otpExpiresAt,
      createdAt: loanData?.loan?.user.createdAt,
      loans: loanData?.loan?.user?.loans,
      profilePhoto: loanData?.loan?.user?.profilePhoto,
      CRBUsers: loanData?.loan?.user?.CRBUsers,
    });
    setPhoneNumber(loanData?.loan?.user?.phoneNumber.split("+")[1]);

    const tempCode = classifyPhoneNumber(
      loanData?.loan?.user?.phoneNumber.split("+")[1]
    );
    if (tempCode !== "Unknown") {
      if (tempCode === "AIRTEL") {
        setServiceCode("ZM-AIRTEL-DISBURSEMENT");
      } else if (tempCode === "MTN") {
        setServiceCode("ZM-MTN-DISBURSEMENT");
      } else if (tempCode === "ZAMTEL") {
        setServiceCode("ZM-ZAMTEL-DISBURSEMENT");
      }
    }
  }, [loanData]);
  const [uploadModalName, setUploadModalName] = useState(null);
  const [uploadAttachment, setUploadAttachment] = useState(null);
  const handleClickUpload = (name) => {
    setUploadModalName(name);
    setUploadModal(true);
  };
  const handleUploadAttachmentFile = (event) => {
    setUploadAttachment({
      extension: event.target.files[0]?.type.split("/")[1],
      name: "Attachment",
    });
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(`#upload_attachment`);
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleUploadSubmit = () => {
    const file = document.querySelector(`#upload_attachment`);

    handleUploadAttachment(uploadModalName, uploadAttachment, file);
  };
  const [errors, setErrors] = useState({});
  const [optionSelected, setOptionSelected] = useState(0);
  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
    // setErrors({
    //   ...errors,
    //   //   [field]: validateField(field, event.target.value),
    // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const formErrors = validateForm(formData);
    // if (Object.keys(formErrors).length === 0) {
    //   // Form is valid, proceed with submission
    // } else {
    //   setErrors(formErrors);
    // }
  };
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: "8px",
    "&.MuiChip-outlined": {
      borderColor: "var(--border-focus)",
      color: "var(--border-focus)",
    },
  }));

  // const loanType = [
  //   { value: "Personal Loan", label: "Personal Loan" },
  //   { value: "SME Loan", label: "SME Loan" },
  //   { value: "Agricultural Loan", label: "Agricultural Loan" },
  //   { value: "Educational Loan", label: "Educational Loan" },
  //   { value: " Other", label: " Other" },
  // ];
  const publicService = [
    { label: "Not A Public Servant", value: "not a public servant" },
    { label: "Government Departments", value: "government departments" },
    { label: "Air Force", value: "air force" },
    { label: "Army", value: "army" },
    { label: "National Services", value: "national services" },
  ];
  const UserStatus = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Blocked", value: "blocked" },
    { label: "Deleted", value: "deleted" },
  ];
  const loanTypes = productData
    ? productData?.products?.map((product) => {
        return { value: product.name, label: product.name };
      })
    : [];
  const getStatus = (value, row) => {
    return `${row.datePaid ? "Paid" : "Pending"}`;
  };
  const headCells = [
    {
      field: "dueDate",
      numeric: false,
      disablePadding: false,
      headerName: "Due Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      flex: 1,
    },
    {
      field: "amount",
      numeric: false,
      disablePadding: false,
      headerName: "Amount Due",
      type: "string",
      valueGetter: (amount) => {
        return (amount = `K ${amount}`);
      },
      flex: 1,
    },

    {
      field: "amountPaid",
      numeric: true,
      disablePadding: false,
      headerName: "Amount Paid",
      flex: 1,
      valueGetter: (amountPaid) => {
        return (amountPaid = `K ${amountPaid}`);
      },
    },
    {
      field: "datePaid",
      numeric: true,
      disablePadding: false,
      type: "date",
      headerName: "Date Paid",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      flex: 1,
    },
    {
      field: "penaltyAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Penalty Amount",
      valueGetter: (penaltyAmount) => {
        return (penaltyAmount = `K ${penaltyAmount ? penaltyAmount : 0}`);
      },
      flex: 1,
    },
    {
      field: "insuranceFee",
      numeric: true,
      disablePadding: false,
      headerName: "Insurance Paid",
      valueGetter: (insuranceFee) => {
        return (insuranceFee = `K ${insuranceFee ? insuranceFee : 0}`);
      },
      flex: 1,
    },
    {
      field: "interestFee",
      numeric: true,
      disablePadding: false,
      headerName: "Interest Paid",
      valueGetter: (interestFee) => {
        return (interestFee = `K ${interestFee ? interestFee : 0}`);
      },
      flex: 1,
    },
    {
      field: "processingFee",
      numeric: true,
      disablePadding: false,
      headerName: "Processing Paid",
      valueGetter: (processingFee) => {
        return (processingFee = `K ${processingFee ? processingFee : 0}`);
      },
      flex: 1,
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      valueGetter: getStatus,
      flex: 1,
    },
  ];
  const timelineCells = [
    {
      field: "date",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "status",
      numeric: false,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
    },
    {
      field: "remarks",
      numeric: false,
      disablePadding: false,
      headerName: "Remarks",
      flex: 1,
    },
    {
      field: "url",
      numeric: false,
      disablePadding: false,
      headerName: "Attachment",
      renderCell: (url) => {
        return (
          <>
            {url?.value?.length > 0 ? (
              <Button
                onClick={() => {
                  const newTab = window.open(url?.value, "_blank");
                  if (newTab) newTab.focus();
                }}
              >
                View
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  const paymentsCells = [
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "payment_status",
      numeric: false,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
    },
    {
      field: "payment_type",
      numeric: false,
      disablePadding: false,
      headerName: "Payment Type",
      flex: 1,
      valueGetter: (type) => {
        return type === "manual_disbursement_payment"
          ? "Manual Disbursement"
          : type === "tingg_installment_payment"
          ? "Tingg Installment"
          : type === "manual_installment_payment"
          ? "Manual Installment"
          : type === "tingg_installment_payment"
          ? "Tingg Disbursement"
          : "";
      },
    },

    // {
    //   field: "url",
    //   numeric: false,
    //   disablePadding: false,
    //   headerName: "Attachment",
    //   renderCell: (url) => {
    //     return (
    //       <>
    //         {url?.value?.length > 0 ? (
    //           <Button
    //             onClick={() => {
    //               const newTab = window.open(url?.value, "_blank");
    //               if (newTab) newTab.focus();
    //             }}
    //           >
    //             View
    //           </Button>
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    if (actionModal) {
      if (errors.attachment) {
        setModalDisable(true);
      } else {
        setModalDisable(false);
      }
    } else if (requestModal) {
      if (errors.remarks || !remark) {
        setRequestDisable(true);
      } else {
        setRequestDisable(false);
      }
    } else if (payModal) {
      if (errors.amount || !amount) {
        setAmountDisable(true);
      } else {
        setAmountDisable(false);
      }
    }
  }, [actionModal, errors, requestModal, remark, payModal, amount]);
  const attachmentsCell = [
    {
      field: "name",
      numeric: false,
      disablePadding: false,
      headerName: "Name",
      type: "string",
      flex: 1,
    },
    {
      field: "url",
      numeric: false,
      disablePadding: false,
      headerName: "Attachment",
      flex: 1,
      renderCell: (url) => {
        return (
          <>
            {url?.value?.length > 0 ? (
              <Button
                onClick={() => {
                  const newTab = window.open(url?.value, "_blank");
                  if (newTab) newTab.focus();
                }}
              >
                View
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "type",
      numeric: false,
      disablePadding: false,
      headerName: "Upload",
      flex: 1,
      renderCell: (type) => {
        return (
          <>
            <Button
              onClick={() => {
                handleClickUpload(type.value);
              }}
            >
              ReUpload
            </Button>
          </>
        );
      },
    },
  ];
  const requestCells = [
    {
      field: "date",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "status",
      numeric: false,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
    },
    {
      field: "requestType",
      numeric: false,
      disablePadding: false,
      headerName: "Request Type",
      type: "string",
      // valueGetter: (requestType) => {
      //   return (requestType = `${toTitleCase(requestType)}`);
      // },
    },
    {
      field: "remark",
      numeric: false,
      disablePadding: false,
      headerName: "Remarks",
      flex: 1,
    },
    {
      field: "url",
      numeric: false,
      disablePadding: false,
      headerName: "Attachment",
      renderCell: (url) => {
        return (
          <>
            {url?.value?.length > 0 ? (
              <Button
                onClick={() => {
                  const newTab = window.open(url?.value, "_blank");
                  if (newTab) newTab.focus();
                }}
              >
                View
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  const Verified = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];
  const Status = [
    { label: "Pending", value: "pending" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Approved", value: "approved" },
    { label: "Funded", value: "funded" },
    { label: "Paid", value: "paid" },
    { label: "Rejected", value: "rejected" },
    { label: "Overdue", value: "overdue" },
  ];
  useEffect(() => {
    const validationErrors = validateLoanInformation(formData);
    // setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [formData]);
  const handleError = (field) => (event) => {
    setErrors((prevState) => ({
      ...prevState,
      [field]: event,
    }));
  };
  const employmentStatus = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div></div>
            <div className={styles.user__inner}>
              {AuthenticatedUser?.admin?.permissions?.permissions
                ?.viewLiveLoanReport &&
                reportData && (
                  // <Button
                  //   children={"Generate Live Report"}
                  //   onClick={handleReport}
                  // />
                  <LoanPDFDownload loanDetails={reportData} />
                )}
            </div>
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>Loan Details</h1>
            <div className={styles.form__inner}>
              <CustomInput
                label="Loan ID"
                value={formData.loanId}
                type="text"
                onChange={handleChange("loanId")}
                error={errors.loanId}
                className={styles.input}
                disabled
              />
              <CustomSelect
                label="Loan Type"
                options={loanTypes}
                value={formData.loanType}
                className={styles.input}
                onChange={(e) => {
                  handleError("loanType")(validateLoanType(e[0]));
                  handleChange("loanType")(e[0]);
                }}
              />
              <CustomInput
                label="Monthly Income"
                value={formData.netMonthlyIncome}
                type="text"
                onChange={(e) => {
                  handleError("netMonthlyIncome")(
                    validateNetMonthlyIncome(e?.target?.value)
                  );
                  handleChange("netMonthlyIncome")(e?.target?.value);
                }}
                error={errors.netMonthlyIncome}
                className={styles.input}
              />
              <CustomInput
                label="Address Duration"
                value={formData.addressDuration}
                type="text"
                onChange={(e) => {
                  handleError("addressDuration")(
                    validateAddressDuration(e?.target?.value)
                  );
                  handleChange("addressDuration")(e?.target?.value);
                }}
                error={errors.addressDuration}
                className={styles.input}
              />
              <CustomSelect
                options={employmentStatus}
                label="Employment Status"
                value={
                  formData.employmentStatus === "employed" ||
                  formData.employmentStatus === "Yes"
                    ? "Yes"
                    : "No"
                }
                type="text"
                onChange={(e) => {
                  handleError("employmentStatus")(
                    validateEmploymentStatus(e[0])
                  );
                  handleChange("employmentStatus")(e[0]);
                }}
                error={errors.employmentStatus}
                className={styles.input}
              />
              <CustomInput
                label="Employer"
                value={formData.employer}
                onChange={(e) => {
                  handleError("employer")(validateEmployer(e?.target?.value));
                  handleChange("employer")(e?.target?.value);
                }}
                className={styles.input}
                error={errors.employer}
              />
              <CustomInput
                label="Employee Number"
                value={formData.employeeNumber}
                onChange={(e) => {
                  handleError("employeeNumber")(
                    validateEmployeeNumber(e?.target?.value)
                  );
                  handleChange("employeeNumber")(e?.target?.value);
                }}
                className={styles.input}
                error={errors.employeeNumber}
              />
              <CustomInput
                label="Loan Amount"
                value={formData.loanAmount}
                type="string"
                disabled
                onChange={handleChange("loanAmount")}
                className={styles.input}
                error={errors.loanAmount}
              />
              <CustomInput
                label="Net Loan Amount"
                value={formData.netLoanAmount}
                type="string"
                disabled
                onChange={handleChange("netLoanAmount")}
                className={styles.input}
                error={errors.netLoanAmount}
              />
              <CustomInput
                label="Loan Duration"
                value={formData.loanDuration}
                type="string"
                disabled
                onChange={handleChange("loanDuration")}
                className={styles.input}
                error={errors.loanDuration}
              />
              <CustomSelect
                label="Public Service"
                options={publicService}
                value={formData.publicService.toLowerCase()}
                className={styles.input}
                onChange={(e) => {
                  handleError("publicService")(validatePublicService(e[0]));

                  handleChange("publicService")(e[0]);
                }}
              />

              <CustomSelect
                label="Status"
                options={Status}
                value={formData.status}
                disabled
                className={styles.input}
                onChange={handleChange("status")}
              />

              <ResponsiveDatePicker
                placeholder="Applied At"
                defaultValue={formData.createdAt}
                label="Applied At"
                disabled
                onChange={handleChange("createdAt")}
                width={"30%"}
                error={errors.createdAt}
              />
            </div>
            {AuthenticatedUser?.admin?.permissions?.permissions?.updateLoan && (
              <div className={styles.form__buttons}>
                <Button
                  type="submit"
                  className={styles.submit}
                  onClick={() => {
                    handleUpdate(formData);
                  }}
                  loading={saveChanges}
                  disabled={submitDisable}
                >
                  Submit
                </Button>
                <Button
                  type="cancel"
                  className={`${styles.submit} ${styles.cancel}`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>

          <div className={styles.options}>
            <div className={styles.options__inner}>
              <StyledChip
                label="User Details"
                onClick={() => {
                  setOptionSelected(0);
                }}
                variant={`${optionSelected === 0 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Finances"
                onClick={() => {
                  setOptionSelected(1);
                }}
                variant={`${optionSelected === 1 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Installments"
                onClick={() => {
                  setOptionSelected(2);
                }}
                variant={`${optionSelected === 2 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Timeline"
                onClick={() => {
                  setOptionSelected(3);
                }}
                variant={`${optionSelected === 3 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Requests"
                onClick={() => {
                  setOptionSelected(4);
                }}
                variant={`${optionSelected === 4 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Actions"
                onClick={() => {
                  setOptionSelected(5);
                }}
                variant={`${optionSelected === 5 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Documents"
                onClick={() => {
                  setOptionSelected(6);
                }}
                variant={`${optionSelected === 6 ? "outlined" : ""}`}
              />
              <StyledChip
                label="Payments"
                onClick={() => {
                  setOptionSelected(7);
                }}
                variant={`${optionSelected === 7 ? "outlined" : ""}`}
              />
            </div>
          </div>
          <div className={styles.table}>
            {optionSelected === 0 && (
              <div className={styles.form}>
                <div className={styles.user__details}>
                  <div className={styles.user__name}>
                    <img alt="" src={user?.profilePhoto || Logo} />
                    <h3>
                      {user?.firstName} {user?.surName}
                    </h3>
                  </div>
                  <div className={styles.button__view}>
                    <Button children={"View"} />
                  </div>
                </div>
                <h1>User Details</h1>
                <div className={styles.form__inner}>
                  <CustomInput
                    placeholder="User ID"
                    value={user.userId}
                    type="text"
                    label="User ID"
                    // onChange={handleChange("firstName")}

                    disabled={true}
                    error={errors.firstName}
                    className={styles.input}
                  />
                  <CustomInput
                    placeholder="First Name"
                    value={user.firstName}
                    type="text"
                    label="First Name"
                    onChange={handleChange("firstName")}
                    disabled={true}
                    error={errors.firstName}
                    className={styles.input}
                  />
                  <CustomInput
                    placeholder="Last Name"
                    value={user.surName}
                    type="text"
                    label="Last Name"
                    onChange={handleChange("surName")}
                    disabled={true}
                    error={errors.surName}
                    className={styles.input}
                  />
                  <CustomInput
                    placeholder="Phone"
                    value={user.phoneNumber}
                    type="tel"
                    label="Phone"
                    onChange={handleChange("phoneNumber")}
                    disabled={true}
                    className={styles.input}
                    error={errors.phoneNumber}
                  />
                  <CustomInput
                    placeholder="Email"
                    value={user.email}
                    type="string"
                    label="Email"
                    onChange={handleChange("email")}
                    disabled={true}
                    className={styles.input}
                    error={errors.email}
                  />

                  <CustomSelect
                    label="Status"
                    options={UserStatus}
                    value={user.status}
                    className={styles.input}
                    onChange={handleChange("status")}
                    disabled={true}
                  />

                  <CustomInput
                    placeholder="ID Number/Passport Number"
                    value={user.NRCNumber}
                    type="text"
                    label="ID Number/Passport Number"
                    onChange={handleChange("NRCNumber")}
                    disabled={true}
                    className={styles.input}
                    error={errors.NRCNumber}
                  />

                  <CustomSelect
                    label="Verified"
                    options={Verified}
                    value={user.verified}
                    className={styles.input}
                    onChange={handleChange("verified")}
                    disabled={true}
                  />

                  <CustomInput
                    placeholder="Account Number"
                    value={user.accountNumber}
                    type="text"
                    label="Account Number"
                    onChange={handleChange("accountNumber")}
                    disabled={true}
                    className={styles.input}
                    error={errors.accountNumber}
                  />
                  <CustomInput
                    placeholder="Bank Name"
                    value={user.bankName}
                    type="text"
                    label="Bank Name"
                    onChange={handleChange("bankName")}
                    disabled={true}
                    className={styles.input}
                    error={errors.bankName}
                  />
                  <CustomInput
                    placeholder="Residential Address Number"
                    value={user.residentialAddressNumber}
                    type="text"
                    label="Residential Address Number"
                    onChange={handleChange("residentialAddressNumber")}
                    disabled={true}
                    className={styles.input}
                    error={errors.residentialAddressNumber}
                  />
                  <CustomInput
                    placeholder="Residential Area"
                    value={user.residentialArea}
                    type="text"
                    label="Residential Area"
                    onChange={handleChange("residentialArea")}
                    disabled={true}
                    className={styles.input}
                    error={errors.residentialArea}
                  />
                  <CustomInput
                    placeholder="Street Name"
                    value={user.streetName}
                    type="text"
                    label="Street Name"
                    onChange={handleChange("streetName")}
                    disabled={true}
                    className={styles.input}
                    error={errors.streetName}
                  />

                  <CountrySelect
                    value={user.country}
                    label="Nationality"
                    className={styles.input}
                    type="Country"
                    onChange={handleChange("country")}
                    disabled={true}
                  />
                  {user?.CRBUsers && (
                    <CustomInput
                      placeholder="CRB Score"
                      value={user?.CRBUsers[0]?.score}
                      type="text"
                      label="CRB Score"
                      onChange={() => {}}
                      disabled={true}
                      className={styles.input}
                      error={errors.streetName}
                    />
                  )}
                  <ResponsiveDatePicker
                    placeholder="Date of Birth"
                    defaultValue={user.dateOfBirth}
                    label="Date of Birth"
                    onChange={handleChange("dateOfBirth")}
                    disabled={true}
                    width={"30%"}
                    error={errors.dateOfBirth}
                  />
                  <ResponsiveDatePicker
                    placeholder="Joined At"
                    defaultValue={user.createdAt}
                    label="Joined At"
                    onChange={handleChange("createdAt")}
                    disabled={true}
                    width={"30%"}
                    error={errors.createdAt}
                  />
                </div>
              </div>
            )}
            {optionSelected === 1 && (
              <div>
                <div className={styles.form} onSubmit={handleSubmit}>
                  <h1>Finances</h1>
                  <div className={styles.form__inner}>
                    <CustomInput
                      label="Paid Loan"
                      value={formData.paidLoan}
                      type="text"
                      onChange={handleChange("paidLoan")}
                      error={errors.paidLoan}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Remaining Loan"
                      value={parseFloat(
                        (
                          parseFloat(formData.netLoanAmount) -
                            parseFloat(formData.paidLoan) || 0
                        ).toFixed(2)
                      )}
                      type="text"
                      onChange={handleChange("paidLoan")}
                      error={errors.paidLoan}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Insurance Rate"
                      value={formData.insuranceRate}
                      type="text"
                      onChange={handleChange("insuranceRate")}
                      error={errors.insuranceRate}
                      className={styles.input}
                      disabled
                    />

                    <CustomInput
                      label="Interest Rate"
                      value={formData.interestRate}
                      type="text"
                      onChange={handleChange("interestRate")}
                      error={errors.interestRate}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Processing Rate"
                      value={formData.processingRate}
                      type="text"
                      onChange={handleChange("processingRate")}
                      error={errors.processingRate}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Penalty Rate"
                      value={formData.penaltyRate}
                      onChange={handleChange("penaltyRate")}
                      className={styles.input}
                      disabled
                      error={errors.penaltyRate}
                    />
                    <CustomInput
                      label="Insurance Paid"
                      value={formData.paidInsuranceFee}
                      type="text"
                      onChange={handleChange("paidInsuranceFee")}
                      error={errors.paidInsuranceFee}
                      className={styles.input}
                      disabled
                    />

                    <CustomInput
                      label="Interest Paid"
                      value={formData.paidInterest}
                      type="text"
                      onChange={handleChange("paidInterest")}
                      error={errors.paidInterest}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Processing Paid"
                      value={formData.paidProcessingFee}
                      type="text"
                      onChange={handleChange("paidProcessingFee")}
                      error={errors.paidProcessingFee}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Penalty Paid"
                      value={formData.paidPenalty}
                      onChange={handleChange("paidPenalty")}
                      className={styles.input}
                      disabled
                      error={errors.paidPenalty}
                    />
                    <CustomInput
                      label="Total Insurance"
                      value={formData.insuranceFee}
                      type="text"
                      onChange={handleChange("insuranceFee")}
                      error={errors.insuranceFee}
                      className={styles.input}
                      disabled
                    />

                    <CustomInput
                      label="Total Interest"
                      value={formData.interestFee}
                      type="text"
                      onChange={handleChange("interestFee")}
                      error={errors.interestFee}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Total Processing"
                      value={formData.processingFee}
                      type="text"
                      onChange={handleChange("processingFee")}
                      error={errors.processingFee}
                      className={styles.input}
                      disabled
                    />
                    <CustomInput
                      label="Total Penalty"
                      value={formData.penaltyFee}
                      onChange={handleChange("penaltyFee")}
                      className={styles.input}
                      disabled
                      error={errors.penaltyFee}
                    />
                    <CustomInput
                      label="Loan Duration"
                      value={formData.loanDuration}
                      type="string"
                      onChange={handleChange("loanDuration")}
                      className={styles.input}
                      disabled
                      error={errors.loanDuration}
                    />
                    <CustomSelect
                      label="Public Service"
                      options={publicService}
                      value={formData.publicService.toLowerCase()}
                      className={styles.input}
                      disabled
                      onChange={handleChange("publicService")}
                    />

                    <CustomSelect
                      label="Status"
                      options={Status}
                      value={formData.status}
                      className={styles.input}
                      disabled
                      onChange={handleChange("status")}
                    />

                    <ResponsiveDatePicker
                      placeholder="Applied At"
                      defaultValue={formData.createdAt}
                      label="Applied At"
                      onChange={handleChange("createdAt")}
                      width={"30%"}
                      disabled
                      error={errors.createdAt}
                    />
                  </div>
                </div>
              </div>
            )}
            {optionSelected === 2 && (
              <CustomTable
                headCells={headCells}
                rows={formData?.installmentDate || []}
                type={`Installments`}
                clickableRows={false}
              />
            )}
            {optionSelected === 3 && (
              <CustomTable
                headCells={timelineCells}
                rows={formData?.timeline || []}
                type={`Timeline`}
                clickableRows={false}
              />
            )}
            {optionSelected === 4 && (
              <CustomTable
                headCells={requestCells}
                rows={formData?.requests || []}
                type={`Requests`}
                clickableRows={false}
              />
            )}
            {optionSelected === 5 && (
              <div className={styles.card}>
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.reviewLoan &&
                  formData.status === "pending" && (
                    <div className={styles.card__inner}>
                      <h3>Review Loan</h3>
                      <Button
                        children={"Review"}
                        onClick={() => {
                          setModalType("Review");
                          setActionModal(true);
                        }}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.approveLoan &&
                  formData.status === "reviewed" && (
                    <div className={styles.card__inner}>
                      <h3>Approve Loan</h3>
                      <Button
                        children={"Approve"}
                        onClick={() => {
                          setModalType("Approve");
                          setCRBCheck(true);
                        }}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.approveLoan &&
                  formData.status === "reviewed" && (
                    <div className={styles.card__inner}>
                      <h3>Check CRB</h3>
                      <Button
                        children={"Check CRB for User"}
                        onClick={() => {
                          checkCRB(user?.id);
                        }}
                        loading={popupLoading}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions?.fundLoan &&
                  formData.status === "approved" && (
                    <div className={styles.card__inner}>
                      <h3>Fund Loan</h3>
                      <Button
                        children={"Fund"}
                        onClick={() => {
                          setModalType("Fund");
                          setFundModal(true);
                        }}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.payInstallment &&
                  (formData.status === "funded" ||
                    formData.status === "overdue") && (
                    <div className={styles.card__inner}>
                      <h3>Pay Installment</h3>
                      <Button
                        children={"Pay Installment"}
                        onClick={() => {
                          setPayModal(true);
                        }}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.extendLoan &&
                  (formData.status === "funded" ||
                    formData.status === "overdue") && (
                    <div className={styles.card__inner}>
                      <h3>Extend Loan Period</h3>
                      <Button
                        children={"Extend"}
                        onClick={() => {
                          setExtendModal(true);
                        }}
                      />
                    </div>
                  )}
                {(formData.status === "pending" ||
                  formData.status === "reviewed") &&
                  AuthenticatedUser?.admin?.permissions?.permissions
                    ?.rejectLoan && (
                    <div className={styles.card__inner}>
                      <h3>Reject Loan</h3>
                      <Button
                        children={"Reject"}
                        className={styles.delete}
                        onClick={() => {
                          setModalType("Reject");
                          setActionModal(true);
                        }}
                      />
                    </div>
                  )}
                {AuthenticatedUser?.admin?.permissions?.permissions
                  ?.requestLoanItem &&
                  formData.status !== "paid" &&
                  formData.status !== "rejected" && (
                    <div className={styles.card__inner}>
                      <h3>Request User</h3>
                      <Button
                        children={"Request"}
                        onClick={() => {
                          setRequestModal(true);
                        }}
                      />
                    </div>
                  )}
              </div>
            )}
            {optionSelected === 6 && (
              <CustomTable
                headCells={attachmentsCell}
                rows={formData?.attachments || []}
                type={`Documents`}
                clickableRows={false}
                // showButton={true}
                // handleButton={handleCreateRole}
              />
            )}
            {optionSelected === 7 && (
              <CustomTable
                headCells={paymentsCells}
                rows={formData?.payments || []}
                type={`Payments`}
                clickableRows={false}
              />
            )}
          </div>
        </>
      )}
      <PayModal
        open={payModal}
        setOpen={setPayModal}
        loading={popupLoading}
        onClick={() => {
          handlePayInstallment(amount);
        }}
        input={amount}
        setInput={(e) => {
          handleError("amount")(validateAmount(e));
          setAmount(e);
        }}
        disabled={amountDisable}
        error={errors.amount}
      />
      <FundModal
        open={fundModal}
        setOpen={setFundModal}
        loading={popupLoading}
        fundType={fundType}
        setFundType={setFundType}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        serviceCode={serviceCode}
        setServiceCode={setServiceCode}
        onClick={() => {
          console.log("Hello");
          const file = document.querySelector(`#attachment`);
          handleFund(
            fundType,
            phoneNumber,
            serviceCode,
            modalType,
            remark,
            attachment,
            file
          );
        }}
        disabled={amountDisable}
        error={errors.amount}
      />
      <DeleteModal
        name={"Loan"}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onClick={handleDelete}
        loading={deleteLoading}
      />
      <ActionModal
        open={actionModal}
        setOpen={setActionModal}
        name={modalType}
        loading={popupLoading}
        onClick={handleAction}
        input={remark}
        setInput={setRemark}
        attachment={attachment}
        handleAttachment={handleAttachment}
        error={errors.attachment}
        modalDisable={modalDisable}
      />
      <UploadModal
        onClick={handleUploadSubmit}
        open={uploadModal}
        setOpen={setUploadModal}
        loading={popupLoading}
        handleAttachment={handleUploadAttachmentFile}
        error={errors.attachment}
        modalDisable={modalDisable}
        name={uploadModalName}
      />
      <RequestModal
        open={requestModal}
        setOpen={setRequestModal}
        loading={popupLoading}
        onClick={() => {
          handleRequest(remark, requestType);
        }}
        input={remark}
        setInput={(e) => {
          handleError("remarks")(validateRemarks(e));
          setRemark(e);
        }}
        requestType={requestType}
        setRequestType={setRequestType}
        error={errors.remarks}
        disabled={requestDisable}
      />
      <ExtendModal
        open={extendModal}
        setOpen={setExtendModal}
        loading={popupLoading}
        onClick={() => {
          handleExtend(month);
        }}
        month={month}
        setMonth={setMonth}
      />
      <CRB
        open={CRBCheck}
        setOpen={setCRBCheck}
        loading={popupLoading}
        onClick={() => {
          handleCRB();
        }}
        month={month}
        setMonth={setMonth}
      />
    </section>
  );
};

export default Loan;
